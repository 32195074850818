<template>
  <layout>
    <template slot="body">
      <div class="margin-container d-flex flex-column">
        <span class="page-title">
          {{ $t('pages.cookiesPolicy.title') }}
        </span>

        <div ref="consent" />
      </div>

      <!-- <a href="javascript: Cookiebot.renew()">Renew or change your cookie consent</a> -->
    </template>
  </layout>
</template>
<script>
import Layout from '@/components/layout/Layout';

export default {
  name: 'CookiesPolicyScreen',
  components: {
    Layout,
  },
  data() {
    return {
    };
  },
  mounted() {
    const cookietbotScript = document.createElement('script');

    cookietbotScript.type = 'text/javascript';
    cookietbotScript.src = 'https://consent.cookiebot.com/1c7a39c6-14c7-47b0-8b84-903ad4fed605/cd.js';
    cookietbotScript.id = 'CookieDeclaration';
    cookietbotScript.async = true;

    this.$refs.consent.appendChild(cookietbotScript);
  },
  methods: {
  },
};
</script>
<style lang="scss" scoped>
.page-title {
  font-weight: 600;
  font-size: 38px;
  line-height: 48px;
}

.section {
  &--title {
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
    margin-top: 10px;
  }

  &--subtitle {
    font-weight: 500;
    font-size: 20px;
  }
}

::v-deep .bold {
  font-weight: 600;
}

.margin-container {
  margin-top: 150px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

@media only screen and (max-width: 770px) {
  .margin-container {
    margin-top: 100px;
  }
}
</style>

<template>
  <div class="main-background">
    <Header />
    <Sidebar />
    <UserSidebar />
    <slot name="body" />
    <Footer />
    <SubFooter />
  </div>
</template>
<script>
import Header from '@/components/layout/Header'
import Sidebar from '@/components/layout/Sidebar'
import UserSidebar from '@/components/layout/UserSidebar'
import Footer from '@/components/layout/Footer'
import SubFooter from '@/components/layout/SubFooter'

export default {
  name: 'Layout',
  components: {
    Header,
    Sidebar,
    UserSidebar,
    Footer,
    SubFooter,
  },
}
</script>
<style lang="scss" scoped>
.main-background {
  display: flex;
  flex-direction: column;
  background-color: $tertiary;
}
</style>
